@import "../base/_colors"

.last-created-user-pass-modal
    &.formModal.ant-modal
        .ant-modal-title
            font: 15px/6px "Red Hat Display Bold"
            height: 15px
        .ant-modal-header
            padding: 24px
        .user-info
            display: flex
            gap: 24px
            align-items: center
            margin-bottom: 24px
            img
                width: 102px
                height: 102px
                object-fit: cover
                border-radius: 50%
            .detals
                h4
                    font: 17px/19px "Red Hat Display Bold"

                h5
                    font: 14px/19px "Red Hat Display Regular"
        button.default
            
            font: 15px/20px "Red Hat Display Regular" !important
            border-radius: 0
            padding: 4px 8px !important
            transform: translateX(-8px)
            &:hover
                color: $button-done-color !important
            &:focus
                background: $select-dropdown-bg-color 0% 0% no-repeat padding-box
