@import '../base/_colors'

.header
    &-account
        padding: 0 24px
        display: flex
        align-items: center
        justify-content: center
        height: auto  !important
        & .logout-text
            font: 14px/24px "Red Hat Display Regular"
            margin: 0
            cursor: pointer
            color: $logo-text-color
            &:hover
                color: rgba($icon-focus-color, 0.7)
            &:focus,&:active
                color: $icon-focus-color
        & .logout
            position: relative
            background: #41479B 0% 0% no-repeat padding-box
            width: 30px
            height: 30px  !important
            border-radius: 100%
            align-items: center 
            justify-content: center
            cursor: pointer
           