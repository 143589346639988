.email-new-pass-modal
    & .ant-modal-header
        padding: 24px !important
    & .ant-checkbox-wrapper
        padding: 4px 8px
        & span
            color: $project-global-color
        & .ant-checkbox-inner
            border-color: $project-global-color
        &:hover
            & span
                color: $icon-focus-color
            & .ant-checkbox-checked .ant-checkbox-inner:after, & .ant-checkbox-checked::after,& .ant-checkbox-inner
                border-color: $icon-focus-color
        &:active,&:focus
            background: $select-dropdown-bg-color 0 0%no-repeat padding-box
            border-radius: 13px
            & .ant-checkbox-inner
                background: $select-dropdown-bg-color 0 0%no-repeat padding-box
    .email-text
        background: transparent linear-gradient(180deg, rgba(248, 248, 250, 1) 0%, rgba(242, 242, 247, 1) 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 4px
        opacity: 0.7
        padding: 16px
        color: $project-global-color
        .bold
            font-weight: 600
