.ant-upload.ant-upload-drag
    background: #FFFFFF 0 0 no-repeat padding-box !important
    border-radius: 2px
    border: 1px dashed $upload-file-border-color
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover
    border-color: $upload-file-border-color
.ant-upload.ant-upload-drag p.ant-upload-text,
.ant-upload.ant-upload-drag p.ant-upload-hint
    text-align: center
    font: 300 12px/16px "SF Pro Display Regular"
    letter-spacing: 0
    color: #191738
    & .marked
        text-align: center
        text-decoration: underline
        font:  300 14px/16px "SF Pro Display Regular"
        letter-spacing: 0
        color: #FF8659