
.ant-tree-checkbox-indeterminate
    .ant-tree-checkbox-inner
        border-color: #191738
        &::after
            background-color: #191738
.ant-tree-checkbox-checked
    .ant-tree-checkbox-inner
        background-color: #FFF
        border-color: #191738
        &::after
            border: 2px solid #191738
            border-top: 0
            border-left: 0
.ant-tree-title
    font: 14px/22px "Red Hat Display Regular"
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner
    border-color: #191738
.ant-tree-checkbox-checked::after
    border-color: #191738
.role-modal
    .help-text
        left: 24px
    &.formModal.ant-modal
        .ant-modal-body
            padding: 0px 0px 24px 0px
        .MainModal--footer
            padding: 24px 24px 0 24px
    #role-form
        label
            text-transform: lowercase
            & > *::first-letter
                text-transform: uppercase
        .role-body
            max-height: 440px
            min-height: 440px
            overflow-y: auto
            padding-right: 24px
            padding-left: 24px
.ant-tree
    & .ant-tree-list
        & .ant-tree-list-holder
            & .ant-tree-list-holder-inner
                & .ant-tree-treenode
                    &:hover
                        .ant-tree-node-content-wrapper
                            & .ant-tree-title
                                color: $icon-focus-color
                        & .ant-tree-checkbox .ant-tree-checkbox-inner,
                        & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner,
                        & .ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after
                            border-color: $icon-focus-color
                        & .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after
                            background-color: $icon-focus-color !important

                    &:focus, &:active
                        .ant-tree-checkbox,.ant-tree-node-content-wrapper, .ant-tree-checkbox-indeterminate
                            color: $icon-focus-color
                            background: $select-dropdown-bg-color 0 0%no-repeat padding-box
                        .ant-tree-checkbox, .ant-tree-checkbox-indeterminate
                            border-top-left-radius: 13px
                            border-bottom-left-radius: 13px
                        .ant-tree-node-content-wrapper
                            border-top-right-radius: 13px
                            border-bottom-right-radius: 13px
                        .ant-tree-checkbox .ant-tree-checkbox-inner:after
                            border-color: $icon-focus-color
                        .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after
                            border-color: $icon-focus-color
                    & .ant-tree-switcher
                        & .anticon
                            color: rgba($project-text-color, 0.7)
                            &:hover
                                color: rgba($icon-focus-color, 0.7)
                            &:focus, &:active
                                color: $icon-focus-color
                    & .ant-tree-checkbox
                        margin: 0
                        top: 0
                        padding: 4px 8px
                        & .ant-tree-checkbox-inner
                            border-color: rgba($project-text-color, 0.7)
                            &.ant-tree-checkbox-inner:after
                                border-color: rgba($project-text-color, 0.7)

                    & .ant-tree-checkbox-indeterminate
                        padding: 4px 8px
                        & .ant-tree-checkbox-inner:after
                            background-color: rgba($project-text-color, 0.7)

