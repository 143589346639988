@import '@assets/sass/base/_colors' 

.edit-profile
    height: 100%
    width: 100%
    .uploadFileContent
        .show-by-hover
            display: none
    .profile-block
        height: 100%
        width: 100%
        align-items: flex-start
        & > div
            background: #F8F8FA 0% 0% no-repeat padding-box
            border: 1px solid #F2F2F7
            min-height: calc(100% - 24px)
            width: calc(100% - 12px)
            padding: 24px
        .userImgUpload
            margin-bottom: 27px
        .right
            input
                border-left-width: 0px
                padding-left: 0px
                border-right: 1px solid #D6D6DF
                margin-right: 8px
                height: 100%
                border-radius: 0
            .ant-input-suffix
                margin-top: auto
                margin-bottom: auto
                font-size: 16px
            .validation-help
                ul
                    margin-left: 19px
                    font: 14px/22px "Red Hat Display Regular"
                    color: rgba($project-global-color, 0.7)
                    li
                        margin: 8px 0
                span
                    font: 14px/22px "Red Hat Display Medium"
    .ant-form-item-control-input-content
        justify-content: flex-end
        display: flex
    .profile-title
        font: 23px/23px "Red Hat Display Bold"
        color: $project-global-color
        margin-bottom: 32px
        text-transform: capitalize
    .image-types
        font: 13px/22px "Red Hat Display Regular"
        color: rgba($project-global-color, 0.5)
    .image-buttons
        button
            background-color: unset 
            border-width: 0
            font: 15px/6px "Red Hat Display Regular"
            border-radius: 20px
            padding: 4px 8px
            color: $project-global-color
            &:focus
                background-color: unset 
                color: $project-global-color
            &:hover
                color: $botton-default-color
            &:active
                background-color: $select-dropdown-bg-color
            [role="img"]
                margin-right: 8px
            
        .ant-space-item
            &:nth-child(1)
                button
                    margin-left: -7px
                    margin-right: 16px
                
button.submit:hover
    & + .error_box
        display: block
.error_box
    position: absolute
    left: calc(100% + 5px)
    top: -13px
    transform: translateY(-5px)
    display: none
    width: 261px
    border-radius: 4px
    opacity: 1
    padding: 13px 20px
    color: #E25F5F
    background: #FFF1F0 0% 0% no-repeat padding-box
    box-shadow: 0px 0px 15px #1a183b33
    border: 1px solid #FF4D4F
    &::after
        content: ''
        width: 7px
        height: 7px
        display: block
        border-left: 1px solid #FF4D4F
        border-top: 1px solid #FF4D4F
        transform: rotate(-45deg) translateY(-50%)
        position: absolute
        left: -2px
        top: 50%
        background-color: #FFF1F0
.right
    .error_box
        left: calc(100% - 5px - 363px)
        &::after
            right: -2px
            left: unset
            transform: rotate(135deg) translateY(-50%)