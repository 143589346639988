.apps-profit
    & > div:nth-child(2)
        overflow-y: auto
        
    .app-item
        width: 192px
        height: 138px
        padding: 16px
        background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
        border-radius: 12px
        min-width: 192px
        min-height: 138px
        & .title
            font: 14px/19px "Red Hat Display Medium"
            color: $project-text-color
            & h4
                margin-bottom: 12px

        
        .app-image
            width: 76px
            height: 76px
            margin-right: 12px
        & > div
            justify-content: unset
