.uploadFileContent
    position: relative
    background: #41479B 0% 0% no-repeat padding-box
    display: flex
    flex-direction: column
    justify-content: center
    max-height: 400px
    overflow: hidden
    height: 100%
    border-radius: 12px
    .show-by-hover
        position: absolute
        opacity: 1
        width: 100%
        bottom: 0
        height: 28px
        background-color: rgba($project-global-color, 0.7)
        color: #fff
        font: 15px/20px "Red Hat Display Medium"
        display: flex
        align-items: center
        justify-content: center
        &:hover
            color: #fff
        &:focus,&:focus-within,&:focus-visible,&:active
            color: $botton-default-color
    &:hover
        & .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
            color: rgba($icon-focus-color, 0.7)
        & .text
            color: rgba($icon-focus-color, 0.7)
    &:focus
        & .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon
            color: $icon-focus-color
        & .text
            color: $icon-focus-color
    & .progress-bar
        height: 100%
        width: 100%
        align-items: center
        display: flex
        justify-content: center
        flex-direction: column
        background-image: url(@assets/img/loadingImg.svg)
        .ant-progress-inner
            border: unset
            .ant-progress-circle-trail
                stroke: transparent
        .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path
            stroke: #ffffff
        .ant-progress-circle .ant-progress-text, p
            color: #ffffff !important
        
    &.uploaded
        & .ant-upload.ant-upload-drag
            border: transparent
        & .ant-upload-drag-container
           vertical-align: top !important
    &--img
        width: 100%
        object-fit: fill
    &--infoBox
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background:  #191738 0% 0% no-repeat padding-box
        border-radius: 0 0 2px 2px
        padding: 16px 18px
        &--title
            display: none
            text-align: left
            font: 14px/18px "SF Pro Display Regular" 
            letter-spacing: 0
            color: #ffffff
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            width: 235px
        &--btnGroup
            & button
                background: transparent
                border: transparent
                padding: 5px
                cursor: pointer
                & .text
                    display: none
                &:hover,&:active,&:focus
                   background: transparent




    & .ant-upload.ant-upload-drag .ant-upload
        padding: 0
        // background: #41479B 0% 0% no-repeat padding-box
    .ant-upload-drag-container
        // background: #41479B 0% 0% no-repeat padding-box
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
