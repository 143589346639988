.header-tamplate-block
    background: #F8F8FA 0% 0% no-repeat padding-box
    border: 1px solid #F2F2F7
    padding: 24px
    height: 420px
    width: 100%

    .header
        width: 100%
        justify-content: space-between
        align-items: flex-start
        
        .left
            h3
                font: 17px/19px "Red Hat Display Bold"
                color: $project-global-color
                text-transform: capitalize
                margin-bottom: 0
            span
                font: 14px/18px "Red Hat Display Regular"
                color: rgba($project-global-color, 0.7)
        .right
            font: 14px/19px "Red Hat Display Regular"
            color: $project-icon-color
            text-transform: capitalize
            cursor: pointer
            padding: 4px 8px
            white-space: nowrap
            transform: translateY(-4px)
            border-radius: 13px
            &:hover
                color: $select-dropdown-title-color
            &:active
                background-color: $menu-item-bg-active-color
            &.ant-dropdown-open
                color: $select-dropdown-title-color
.duration-popup
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title
        padding: 8px 16px
        text-transform: capitalize
    .ant-dropdown-menu-item-selected
        color: $select-dropdown-title-color
        background-color: transparent
    .ant-dropdown-menu-item-active
        color: $select-dropdown-title-color