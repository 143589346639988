$logo-text-color:#0050B3
$button-done-color: #1890FF
$button-cancel-color: #F8F8FA
$botton-border-color: #002766
$popup-title-color: #002766
$select-dropdown-bg-color: #E6F7FF
$select-dropdown-title-color: #1890FF
$menu-item-title-color: #002766
$menu-item-title-active-color: #002766
$menu-item-bg-active-color: #E6F7FF
$add-button-text-color: #1890FF
$checkbox-border-color: #002766
$checkbox-center-color: #002766
$checkbox-checked-color: #002766
$project-text-color: #002766
$project-icon-color: #002766
$content-title-color: rgba(0, 39, 102, 0.8)
$input-border-hover-color: #002766
$input-title-color: #002766
$botton-default-color: #1890FF
$botton-focus-color: #096DD9
$botton-default-shadow-color: #1890ff29
$project-global-color: #002766
$selected-tab-color: #002766
$selected-tab-border-color: rgb(0, 39, 102, 19%)

$with-icon-color: #D6D6DF

$user-active-color: #52c41a
$user-blocked-color: #f5222d
$user-inactive-color: #7c90a5

$scroll-thumb: #DCDCE4
$scroll-thumb-hover: #E1E1E1
$scroll-track: #FFF


$reset-successful-popup-title-color: #00CE7E
$error-validation-color: #FF4D4F

$statistics-up-color: #00CE7E
$statistics-down-color: #AA193B

$icon-color: rgba(#002766, 0.7)
$icon-focus-color: #1890FF
$icon-hover-color: #002766

$upload-file-border-color:  rgba(#002766, 0.7)