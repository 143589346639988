@import 'node_modules/antd/dist/antd'
@import "base/reset"
@import "base/fonts"
@import "base/typography"
@import "base/flexBoxes"
@import "components/buttons"
@import "components/titleArea"
@import "components/modal"
@import "components/dragger"
@import "components/inputs"
@import "components/uploadFile"
@import "components/table"
@import "layouts/header"
@import "components/loaderAnimation"
@import "components/tableLoader"
@import "components/forms"
@import "components/settings"
@import "components/alertMsg"
@import "components/inputs"
@import "components/tableSearchBar"

@import "components/appGridItem"
@import "components/appControlPopup"
@import "components/roleGridItem"
@import "components/roleControlPopup"
@import "components/usersCard"
@import "components/userControlPopup"
@import "base/_colors"
@import "components/resetPasswordForPopup" 
@import "components/resetPassworSuccessfulPopup"
@import "components/emailNewPasswordPopup"
@import "components/switchTo"
@import "components/headerTamplate"
@import "components/appsProfit"
@import "components/earningChart"
@import "components/earning"
@import "components/betsDashboard"
@import "components/earningTable"
@import "components/showPassworPopup"
@import "components/treeInput"

html
    --antd-wave-shadow-color: $project-text-color
::selection
    background: $project-text-color
body
    color: $project-text-color
    font-feature-settings: unset
.ant-checkbox-checked .ant-checkbox-inner
    background-color: #fff
    border-color: $project-global-color
.ant-checkbox-checked .ant-checkbox-inner::after
    border-color: $project-global-color
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner
    border-color: $project-global-color
.ant-checkbox-checked::after
    border-color: $project-global-color
.ant-menu-item
    text-transform: capitalize
.ant-menu-item a
    color: $menu-item-title-color
a, .fi-rr-check, .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item:hover a
    color: $menu-item-title-active-color
.fi-rr-cross
    color: #E25F5F
.ant-menu-item:hover, .ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu:not(.ant-menu-vertical) .ant-menu-submenu-open,
.ant-menu-submenu-active, .ant-menu-submenu-title:hover
    color: $project-global-color
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after 
        background: $project-global-color

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow
    background: $project-global-color
    color: $project-global-color
.ant-menu-submenu:hover .ant-menu-submenu-title i, .ant-menu-submenu:hover .ant-menu-submenu-title span
    color: $project-global-color
.ant-picker-focused, .ant-picker:hover
    border-color: $project-global-color
    -webkit-box-shadow: 0 0 0 2px $botton-default-shadow-color
    box-shadow: 0 0 0 2px $botton-default-shadow-color
.ant-breadcrumb a:hover
    color: $project-global-color
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after
    left: 0
    right: auto
    background: transparent linear-gradient(180deg, #0050B3 0%, #1890FF 100%) 0 0 no-repeat padding-box
.ant-checkbox-indeterminate .ant-checkbox-inner::after, .ant-spin-dot-item
    border-color: $project-global-color
    background-color: $project-global-color
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after
    width: 4px
    border-right: 0

.ant-menu-vertical .ant-menu-item:hover, .ant-menu-vertical-left .ant-menu-item:hover, .ant-menu-vertical-right .ant-menu-item:hover,
.ant-menu-vertical .ant-menu-item:hover,
.ant-menu-inline .ant-menu-item:hover
    & a
        color: #1890FF

.ant-menu-vertical .ant-menu-item:focus, .ant-menu-vertical-left .ant-menu-item:focus, .ant-menu-vertical-right .ant-menu-item:focus,
.ant-menu-vertical .ant-menu-item:focus,
.ant-menu-inline .ant-menu-item:focus
    background-color: $menu-item-bg-active-color
.ant-pagination-item, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link
    border-color: transparent

.ant-pagination-item-active
    border-color: $project-global-color

.ant-pagination-item-active:focus, .ant-pagination-item-active:hover
    border-color: $project-global-color

.ant-pagination-item:focus, .ant-pagination-item:hover
    border-color: $project-global-color

.ant-pagination-item
    border-radius: 6px

.ant-pagination-item:focus a, .ant-pagination-item:hover a, .ant-spin
    color: $project-global-color

.ant-spin
    width: 100%

.ant-pagination-item-active a
    color: $project-global-color

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon
    color: $project-global-color
.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link
    color: $project-global-color
    border-color: $project-global-color
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    background-color: $menu-item-bg-active-color
.anticon-check
    color: $checkbox-checked-color
.ant-select-item
    color: $project-global-color
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
    color: $menu-item-title-color
    background: $menu-item-bg-active-color 0 0 no-repeat padding-box
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item
    margin: 0
    padding: 10px 19px 10px 16px
    height: 48px
    line-height: normal
.ant-menu-inline .ant-menu-sub  .ant-menu-item::before,
.ant-menu-vertical .ant-menu-sub  .ant-menu-item::before
    content: " "
    border-right: 1px #000 solid
    height: 100%
    width: 0px
    margin-right: 5px
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-vertical > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-sub.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title
    display: flex
//.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item,
//.ant-menu-inline .ant-menu-item,
//.ant-menu-vertical .ant-menu-item
//    padding-left: 20px
.ant-menu-submenu-inline .ant-menu-item-only-child
    margin-bottom: 0px
    margin-top: 0px
    margin-right: 5px
    padding-left: 16px !important

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
    background: $project-global-color
a:active, a:hover, .ant-picker-today-btn, .ant-picker-header-view button:hover, .ant-picker-header-view button:hover
    color: $project-global-color

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before
    border-color: $project-global-color
.site-layout-background, .ant-layout-sider
    background: #fff
.ant-layout-sider
    box-shadow: 0px 0px 20px #1A183B33
.ant-layout-header 
    height: 48px
.ant-btn:hover, .ant-btn:focus
    border-color: $button-done-color
    background: $button-done-color
    color: #fff
.ant-btn-primary
    background: $button-done-color
    border-color: $button-done-color
    .ant-btn-loading-icon
        margin-right: 5px

.ant-modal
    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before
        content: none

label, .ant-modal-title, .ant-select-dropdown, .ant-select-selection-item, .ant-tabs-tab-btn
    text-transform: capitalize
    text-align: left
    font: 14px/22px "SF Pro Display Regular"
    letter-spacing: 0
    color: $project-global-color

.ant-input-affix-wrapper
    color: $project-global-color

    & > *
        height: 18px

    &::before
        height: 18px

.reversedLabel label
    display: flex
    flex-direction: row-reverse
    width: fit-content
.ant-select:focus, .ant-select-focused .ant-select-selector,
.ant-select-multiple:focus,
.ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused
    border-color: $input-border-hover-color
    -webkit-box-shadow: 0 0 0 2px $botton-default-shadow-color
    box-shadow: 0 0 0 2px $botton-default-shadow-color !important
.ant-input
    font-family: "SF Pro Display Regular" !important
    color: $project-global-color
.ant-input:hover
    border-color: $input-border-hover-color
.ant-progress-bg
    border-top-right-radius: 0px
    border-bottom-right-radius: 0px
.ant-progress-text
    position: absolute
    right: 8px
    top: 5px
    width: auto
    color: rgba(25, 23, 56, 1) !important
    font: 12px/17px "Red Hat Display Regular"
.ant-progress-show-info .ant-progress-outer
    padding-right: 0px
.custom-progress-lable
    font-size: 12px
.ant-progress-inner
    border: 4px rgba(220, 220, 228, 1) solid
    background-color: rgba(220, 220, 228, 1)
.one-row
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

/* width */
::-webkit-scrollbar 
  width: 4px
  height: 4px


/* Track */
::-webkit-scrollbar-track 
  background: $scroll-track
  width: 10px


/* Handle */
::-webkit-scrollbar-thumb 
  background: $scroll-thumb


/* Handle on hover */
::-webkit-scrollbar-thumb:hover 
  background:  rgba(#1890FF, 0.3)

.flex-right
    margin-left: auto

.right-line
    &::after
        content: " "
        width: 1px
        background-color: #E1E1E1
        height: 14px
        display: inline-block
        transform: translateX(16px)

.r-32
    margin-right: 32px

.ant-tooltip-inner
    color: #AAAFB5
    padding: 12px 24px
    font: 15px/15px "Red Hat Display Regular"

.fi-sr-pencil
    cursor: pointer

.row-dragging 
    background: #fafafa
    border: 1px solid #ccc

    td 
        padding: 16px
        visibility: hidden

    .drag-visible
        visibility: visible

.ant-table
    th
        text-transform: capitalize
  
.ant-table-cell-fix-left, .ant-table-cell-fix-right
    display: flex
    justify-content: center

.ant-table-cell-fix-right-first:after, .ant-table-cell-fix-right-last:after, .ant-table-container:after
    -webkit-box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%)
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%)

.ant-tag-green
    color: $project-global-color
    background: rgba(68, 180, 133, 0.1)
    border-color: $project-global-color
    &::before
        background-color: $project-global-color

.ant-tag-volcano
    color: #AAAFB5
    background: rgba(170, 174, 181, 0.1)
    border-color: #AAAFB5
    &::before
        background-color: #AAAFB5

.ant-tag-red
    &::before
        background-color: #f5222d

.ant-tag
    width: 80px
    height: 24px
    text-transform: capitalize
    text-align: center
    position: relative
    
    &::before
        content: " "
        width: 7px
        height: 7px
        display: block
        position: absolute
        left: 7px
        top: 7px
        border-radius: 50%

.ant-select, .ant-select-dropdown
    .ant-tag-green, .ant-tag-volcano, .ant-tag-red
        border: unset
        background-color: transparent

.ant-tag.no-style
    background-color: transparent
    border: none
    &::before
        content: none


.ant-select:not(.ant-select-disabled):hover .ant-select-selector
    border-color: $input-border-hover-color
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input,.select-after) .ant-select-selector
    border-color: $input-border-hover-color
    -webkit-box-shadow: 0 0 0 2px rgba(255, 134, 89, 0.2)
    box-shadow: 0 0 0 2px  rgba(255, 134, 89, 0.2)



.ant-table-wrapper
    margin-top: 24px
    table
        border-color: transparent
    .ant-table-tbody > tr > td
        border: none
    .ant-table-tbody > tr:nth-child(2n), .ant-table-tbody > tr:nth-child(2n) td
        background-color: rgba(247, 248, 250, 1)
    .ant-table-tbody > tr, .ant-table-tbody > tr td
        background-color: #fff

main
    
    h2
        letter-spacing: 0px
        font: 26px/34px "Red Hat Display Bold"
        text-transform: capitalize
        margin-top: 24px
.ant-form-item:not(.ant-form-item-has-error)
    margin-bottom: 16px
    
.ant-form-item label:after
    content: none

button
    text-transform: capitalize

.filter-top-block
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    button
        min-width: 138px
        min-height: 40px

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
    padding: 11px 16px

.actions-blocks
    i
        color: #AAAFB5
        cursor: pointer

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a
    color: $project-global-color

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover
    color: rgba(0, 0, 0, 0.25)
    background: #f5f5f5 !important
    border-color: #d9d9d9

.buttons-block
    .ant-form-item-control-input-content
        display: flex
        flex-direction: row
        justify-content: flex-end

        button
            width: 110px
            height: 36px

            &:first-child
                margin-right: 8px
.ant-upload.ant-upload-select-picture-card
    height: 124px

.preogress-buttons
    color: #707078
    .link
        text-decoration: underline
        color: $project-global-color
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active
    background-color: $project-global-color
    color: #FFFFFF
    border-radius: 2px !important

    .ant-tabs-tab-btn
        color: #FFFFFF 
.list-block
    background-image: url(../img/Line.svg)
    background-repeat: repeat-y
// icons
.archive-icon
    &::before
       /* content: url(../img/icon/archive.svg) */

.drag-icon
    cursor: move
    &::before
        content: url(../img/icon/drag.svg)

.cancel-icon
    width: 14px
    height: 14px
    display: inline-block
    cursor: pointer
    &::before
      /*  content: url(../img/icon/cancel.svg) */

.history-icon
    &::before
        content: url(../img/icon/history.svg)

.delete-icon
    &::before
        content: url(../img/icon/delete.svg)
.add-icon
    &::before
        content: url(../img/icon/edit.svg)

.add-note-icon
    &::before
      /*  content: url(../img/icon/add-note.svg) */
.logot-icon
    &::before
        content: url(../img/icon/logout.svg)
.fi-sr-plus
    font-size: 11px
    margin-right: 8px

.fi-sr-cross-small, .fi-rr-globe
    cursor: pointer



.search-list-input
    margin: 0px
    justify-content: space-between
    &.ant-form-item
        flex-direction: row

    .ant-form-item-control
        max-width: 77%
    
        input
            border-color: transparent
            box-shadow: none
            opacity: 0
            padding-right: 47px
            width: 94%
            margin-left: 6%
            &:active, &:focus
                border-color: #E1E1E1
                opacity: 1

    i
        display: inline-block
        transform: translate(-31px, 3px)

    .ant-form-item-label > label
        font-size: 22px
        font-weight: 600
        color: #4D4D4D
.ant-card, .ant-card-head
        border: none
.ant-card-body > div 
    border: 1px #E1E1E1 solid
    border-radius: 5px
    padding-right: 6px
/* custom code */
.centeredBox
    display: flex
    align-items: center
    justify-content: center

.ant-tooltip-inner
    text-align: center
    font: 14px/22px "Red Hat Display Medium"
    letter-spacing: 0
    color: $project-global-color
    opacity: 1
    background: #FFFFFF 0 0 no-repeat padding-box
    box-shadow: 0px 0px 15px #1A183B33

.ant-form-item-label > label
    flex-direction: row-reverse
.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label
    font: 14px/20px "Red Hat Display Regular"
    letter-spacing: 0
    color: $input-title-color
// .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
//     color: $input-title-color

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label
        padding: 0 0 5px

.ant-switch
    min-width: 40px
    height: 24px
    line-height: 24px

    .ant-switch-handle
        width: 20px
        height: 20px

    &.ant-switch-checked .ant-switch-handle
        left: calc(100% - 20px - 2px)
textarea
  resize: none
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child)
    margin-bottom: 0px
.ant-input:placeholder-shown, ::placeholder
    text-transform: capitalize
.ant-input:placeholder-shown, ::placeholder, .ant-select-selection-placeholder
    font: 14px/22px "Red Hat Display Regular"
    color: rgba($project-global-color, 0.5)
[role="alert"]
    text-transform: lowercase
[role="alert"] span
    text-transform: lowercase
    display: inline-block
    margin-right: 5px
[role="alert"]::first-letter, [role="alert"] span::first-letter
    text-transform: capitalize
::placeholder
    font: 14px/22px "Red Hat Display Regular"
    color: rgba($project-global-color, 0.5) !important

::-ms-input-placeholder
    font: 14px/22px "Red Hat Display Regular"
    color: rgba($project-global-color, 0.5) 

::-ms-input-placeholder
    font: 14px/22px "Red Hat Display Regular"
    color: rgba($project-global-color, 0.5) 

.ant-picker-range .ant-picker-active-bar
    background: $project-global-color

.checkbox-mode
    .ant-select-item-option-selected
        flex-direction: row-reverse
    .ant-select-item-option
        align-items: center

    .ant-select-item-option-state
        width: 15px
        height: 15px
        margin-right: 8px
        background-color: #FFF
        color: #fff
        & [role="img"]
            position: relative
            top: -5px
            left: 2px
            width: 10px
            height: 10px
            svg
                width: 10px
                height: 10px

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state
        background-color: #fff
        border: 1px solid $project-global-color
        color: #fff !important
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
        background-color: #fff !important
        align-items: center
    .ant-select-item-option:not(.ant-select-item-option-disabled, .ant-select-item-option-selected)

        &::before
            content: ''
            width: 15px
            height: 15px
            border: 1px solid $project-global-color
            background-color: #fff
            margin-right: 8px
.text-capitalize
    text-transform: capitalize

.client-list-select, .product-list-select
    .ant-select-item-option-content, .ant-select-selection-item
        text-transform: none !important

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav
    display: none

.rc-virtual-list-scrollbar
    width: 4px !important

.text-uppercase
    text-transform: uppercase
.ant-tooltip-arrow-content
    background-color: #fff
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title
    color: $project-global-color
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover
    background-color: transparent

.ant-dropdown-menu-item:focus, .ant-dropdown-menu-submenu-title:focus
    background-color: $select-dropdown-bg-color

.ant-select-item-option-active:not(.ant-select-item-option-disabled)
    font: 14px/21px "Red Hat Display Regular"
    font-weight: normal
    color: $select-dropdown-title-color
    background-color: $select-dropdown-bg-color
.ant-select-item .ant-select-item-option:hover
    background-color: transparent
    color: $project-global-color

.ant-select-item .ant-select-item-option:focus
    background-color: $select-dropdown-bg-color

.ant-select-item .ant-select-item-option
    color: $project-global-color
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    color: $select-dropdown-title-color
    background-color: $select-dropdown-bg-color
    font-weight: normal
    & .ant-select-item-option-state
        display: none
.ant-radio-button-wrapper
    color: $project-global-color
.ant-modal-close-x
    color: $project-global-color
    &:hover,&:active
        color: $icon-focus-color
.ant-tabs
    color: $project-global-color
.ant-tree-title
    color: $project-global-color
h1, h2, h3, h4, h5, h6
    color: $project-global-color
.ant-btn-dashed
    color: $botton-border-color
.text
    color: $project-global-color

.ant-checkbox-wrapper
    color: $project-global-color

    .ant-checkbox-inner
        width: 18px
        height: 18px

        &::after
            width: 6px
            height: 10px
.with-icon
    .ant-input-affix-wrapper
        padding: 8px 12px
        & > input.ant-input
            border-right: 1px solid $with-icon-color
            border-radius: unset
            margin-right: 8px
        .ant-input-suffix
            font-size: 16px
.ant-form-item-control-input, .ant-form-item-control-input-content
    min-height: 34px

.ant-radio-wrapper

    .ant-radio
        top: 0.3em

.formModal.ant-modal .MainModal--footer.small-footer
    margin-top: 0px
    border-top-width: 0px
.medium
    font: 17px/21px "Red Hat Display Medium"
.ant-tooltip-inner
    white-space: nowrap
.global-link-color
    color: $logo-text-color
.block
    display: block
.help-text
    font: 12px/22px "Red Hat Display Regular"
    position: absolute
    bottom: 20px
    color: $project-text-color
    &::before
        display: inline-block
        margin-right: 4px
        color: #ff4d4f
        font-size: 14px
        font-family: SimSun, sans-serif
        line-height: 1
        content: "*"
.column-reverse
    display: flex
    flex-direction: column-reverse