.app-item
    min-width: 220px
    min-height: 166px
    background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
    box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
    border-radius: 12px
    padding: 16px

    h4
      max-width: 160px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
      font: 14px/19px "Red Hat Display Medium"
      color: $project-text-color

    .anticon[tabindex]
      position: relative
      font-size: 18px
      height: 18px
      width: 18px
      margin-bottom: auto
      color: $icon-color
      &:focus
        color: $icon-focus-color
      &:hover
        color: $icon-hover-color

    & > div
        width: 100%
        display: flex
        flex-wrap: nowrap
        justify-content: space-between
        align-items: center
        
    
    .app-image
        width: 100px
        height: 100px
        border-radius: 12px
        border-width: 0px
        object-fit: cover
    .buttons-block
        display: flex
        flex-direction: column
        button, .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover
            min-width: 72px
            max-width: 72px
            padding: 0px
            height: 24px
            border-radius: 12px
            background: transparent
            border-color: transparent
            color: $project-global-color
            box-shadow: unset
            font: 12px/20px "Red Hat Display Medium"

        &.enable
            button:nth-child(1), .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover
                background: #00CE7E
                border-color: #00CE7E
                color: #FFFFFF
        &.disable
            button:nth-child(2)
                background: #7C90A5
                border-color: #7C90A5
                color: #FFFFFF
                opacity: 0.5
        .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover
            opacity: 0.5

.appImgUpload
  & .uploadFileContent
    background-color: transparent
    justify-content: space-between
    &.uploaded
        & .ant-upload.ant-upload-drag.avatar-uploader,
        & .ant-upload.ant-upload-drag.preview-uploader
          & .ant-upload.ant-upload-btn
             & .ant-upload-drag-container
                & .uploadFileContent
                  // flex-direction: unset
    & .ant-upload.ant-upload-drag.avatar-uploader,
    & .ant-upload.ant-upload-drag.preview-uploader
      // background: #41479B 0 0 no-repeat padding-box !important
      outline-offset: -5px
      width: 104px
      height: 104px
      & .ant-upload.ant-upload-btn
        & .ant-upload-drag-container
          & .uploadFileContent
            display: flex
            flex-direction: column
            justify-content: flex-start



    &--img
      width: 104px
      height: 104px
      object-fit: cover
      padding: 0px
      // background: #41479B 0 0 no-repeat padding-box
      outline: 1px dashed #fff
    &--infoBox
      background: transparent
      width: calc(100% - 104px)
      display: flex
      flex-direction: unset
      flex-wrap: wrap
      padding: 0 14px
      margin: 0
      justify-content: flex-end
      align-items: flex-end
      &--title
        color: #41479B
      &--btnGroup
        display: flex
        flex-wrap: wrap
        width: 100%
        & button
          width: 100%
          background: #F0F2F5 0 0 no-repeat padding-box
          border-radius: 2px
          opacity: 1
          display: flex
          align-items: center
          justify-content: center
          flex-direction: row-reverse
          margin: 4px
          width: 188px
          &.delete
            display: none
          &:hover,&:active,&:focus
            color: #41479B
          & .text
            display: block
          & img
            margin: 5px
    & .ant-upload.ant-upload-btn
      background: transparent
      & .ant-upload-drag-container
        background: #FFFFFF 0% 0% no-repeat padding-box
        border-radius: 12px

.grid-item-tooltip
  width: 74px
  height: 50px
  & .ant-tooltip-content .ant-tooltip-arrow
    bottom: -9px
.app-tooltip
  height: 50px
  & .ant-tooltip-inner
    padding: 12px 24px
    line-height: normal
    font: 15px/30px "Red Hat Display Regular"
  & .ant-tooltip-content .ant-tooltip-arrow
    bottom: -9px