.reset-pass-for-modal
    .user-info
        display: flex
        flex-wrap: nowrap
        align-items: center
        margin-bottom: 32px
        margin-top: 8px
        
        & img
            width: 102px
            height: 102px
            object-fit: cover
            border-radius: 50%
        
        .detals
            margin-left: 20px
        
            h4
                font: 17px/19px "Red Hat Display Medium"
                letter-spacing: 0px
                padding: 8px
                margin: 0
                padding-left: 0
            h5
                font: 14px/19px "Red Hat Display Regular"
                letter-spacing: 0px

    .reset-pass-radio-group
        .lable
            font: 15px/19px "Red Hat Display Medium"
            letter-spacing: 0px
            color: $project-global-color
        .description
            font: 12px/19px "Red Hat Display Regular"
            letter-spacing: 0px
            color: rgba($project-global-color, 0.7)
        .ant-radio-inner
            width: 20px
            height: 20px
            border-color: $project-global-color
            &::after
                background: $project-global-color 0% 0% no-repeat padding-box
                border-radius: 50%
                width: 10px
                height: 10px
                left: 4px
                top: 4px
        .ant-radio-checked
            &::after
                border: 1px solid $project-global-color
            .ant-radio-inner
                border-color: $project-global-color
                transition: none
                &::after
                    transition: none
        .ant-space
            .ant-space-item
                &:hover
                    .lable, .description
                        color: #1890FF
                    .ant-radio-inner
                        border-color: #1890FF
                        &::after
                            background: #1890FF
                &:active
                    .lable, .description
                        color: #0050B3
                    .ant-radio-inner
                        border-color: #0050B3
                        &::after
                            background: #0050B3
    .ant-checkbox-inner
        width: 18px
        height: 18px
        &::after
            width: 6px
            height: 10px
    .ant-checkbox-wrapper
        align-items: center
        padding: 4px 8px
        padding-right: 0px
        transform: translateX(-8px)
        .ant-checkbox
            transform: translateY(-2px)
        span
            transition: color 0s
        &:hover
            .ant-checkbox
                .ant-checkbox-inner
                    border-color: $button-done-color
                    &::after
                        border-color: $button-done-color
            span
                color: $button-done-color
        &:focus
            background-color: $select-dropdown-bg-color
            .ant-checkbox
                .ant-checkbox-inner
                    border-color: $button-done-color
                    &::after
                        border-color: $button-done-color
            span
                color: $button-done-color
    .ant-checkbox-wrapper-checked
        &:hover
            .ant-checkbox-checked
                &::after
                    border-color: $button-done-color 
                .ant-checkbox-inner
                    border-color: $button-done-color
                    &::after
                        border-color: $button-done-color 
            span
                color: $button-done-color
        &:focus
            background-color: $select-dropdown-bg-color
            .ant-checkbox-checked
                &::after
                    border-color: $button-done-color 
                .ant-checkbox-inner
                    border-color: $button-done-color
                    &::after
                        border-color: $button-done-color 
            span
                color: $button-done-color
    & .with-icon.ant-form-item-has-error
        & .ant-input-suffix
            & .anticon
                color: #ff4d4f
    &.isShowPassInput .MainModal--footer
        padding: 16px 0 0 24px !important