@import "../base/_colors"
.app-img-block
    width: 192px
    display: flex
    align-items: center
    & .ant-form-item
        width: 100%
    [role="alert"]
        white-space: nowrap
        

    .appImgUpload
        margin-right: 16px
        max-width: 106px !important
    .on-button.ant-radio-button-wrapper, .off-button.ant-radio-button-wrapper
        min-width: 72px
        max-height: 24px
        border-radius: 12px
        background: transparent
        border-width: 0
        color: $project-global-color
        &:hover:not(.ant-radio-button-wrapper-checked)
            color: rgba($icon-focus-color, 0.7)
        &:focus:not(.ant-radio-button-wrapper-checked)
            color: $icon-focus-color

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-radio-button-wrapper:not(:first-child)::before
        content: unset
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within
        box-shadow: 0 0 0 3px rgba(124, 144, 165, 0.08)

    .on-button.ant-radio-button-wrapper-checked
        background: #00CE7E
        color: #FFFFFF
    .off-button.ant-radio-button-wrapper-checked
        background: #7C90A5
        color: #FFFFFF
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon
        margin-bottom: 12px
        margin-top: 21px

        .anticon
            color: $project-global-color
            font-size: 32px
    .uploadFileContent
        &--infoBox
            display: none
        .progress-bar
            border-radius: 12px
            .ant-progress-circle .ant-progress-text
                font-size: 12px
    .ant-upload.ant-upload-drag
        border-radius: 12px

    .appImgUpload .uploadFileContent .ant-upload.ant-upload-drag.avatar-uploader, .appImgUpload .uploadFileContent .ant-upload.ant-upload-drag.preview-uploader
        width: 106px
        height: 106px

#app-form
    position: relative
    .ant-dropdown-trigger
        font: 14px/21px "Red Hat Display Regular"
        color: $project-global-color
        text-transform: uppercase
        border-color: #F2F2F7
        background: #F2F2F7
        box-shadow: none
        padding: 4px 8px
        &:hover
            color: $icon-focus-color
        &:focus
            background: #E6F7FF 0% 0% no-repeat padding-box
            border-radius: 13px
    .permissions-block
        border-color: #F2F2F7
        background: #F2F2F7
        padding: 12px 10px 12px 24px
        margin-bottom: 4px
    
.methods-drop-down .ant-dropdown-menu-title-content
        font: 14px/21px "Red Hat Display Regular"
        color: $project-global-color
        text-transform: uppercase
        &:hover
            color: $icon-focus-color
            
.app-modal.formModal.ant-modal
    .help-text
        left: 24px
    &.hide-help-text
        .help-text
            display: none !important
    .text-capitalize
        text-transform: lowercase
        &::first-letter
            text-transform: uppercase
    .ant-modal-body
        padding: 0 0px 24px 0px
        .tabMode
            label
                text-transform: lowercase
                span::first-letter
                     text-transform: uppercase
    .MainModal--footer
        margin: 0
        border-top: unset
        margin-right: 20px
        // .ant-form-item-control-input-content
        //     padding-right: 24px
    .add-button
        font-size: 25px
        position: absolute
        bottom: 0px
        left: 25px
        z-index: 1
        cursor: pointer
        margin-bottom: 0px
        .anticon
            color: rgba($project-text-color, 0.7)
            &:hover
                color: $project-text-color
            &:focus, &:active
                color: $icon-focus-color
    .permissions-tab
        max-height: 432px
        min-height: 432px
        overflow-y: auto
        
    .permissions-block
        .anticon
            color: rgba($project-text-color, 0.7)
            &:hover
                color: rgba($icon-focus-color, 0.7)
            &:focus, &:active
                color: $icon-focus-color
        .icon
            cursor: pointer
        & > div.inputs:nth-child(1)
                .ant-space-item
                    max-width: 46%

    