@import "../../assets/sass/base/_colors"

.login-block
    background-image: url(../../assets/img/loginFon.svg)
    background-repeat: no-repeat
    background-size: cover
    &>div
        height: 100vh
        display: flex
        justify-content: center
        align-items: center
        margin: auto

        .form-block
            width: 440px
            .ant-form-item-has-error
                .ant-input-suffix
                    .anticon
                        color: $error-validation-color
            .ant-form-item
                &:nth-child(1)
                    margin-bottom: 24px
                &:nth-child(2)
                    margin-bottom: 28px
                &:nth-child(3)
                    margin-bottom: 32px
                    
            .ant-input-affix-wrapper
                padding: 7px 12px
            
                input
                    border-left: 1px solid #D6D6DF
                    padding-left: 12px
                    box-shadow: none !important
                    outline: none !important
                .ant-input-prefix
                    margin-right: 12px
                    font-size: 14px
            h2
                font: 26px/26px "Red Hat Display Regular"
                color: #1890FF
                text-transform: capitalize
                margin-bottom: 32px
            img
                width: 60px
                height: 60px
            .img
                font-size: 40px
                color: $reset-successful-popup-title-color
            p
                text-align: center
                font-size: 14px
                margin: 0px auto 24px auto
                width: 100%
            &.resend
                p
                    width: 100%
                    white-space: nowrap
                h2
                    color: $reset-successful-popup-title-color
                .ant-form-item
                    &:nth-child(2)
                        margin-bottom: 0px

            & form>div
                &:last-child
                    margin-bottom: 0px
            .login-lable
                font: 20px/6px "Red Hat Display Regular"
                color: $project-global-color
            .login-description
                font: 14px/28px "Red Hat Display Regular"
                color: $project-global-color
                padding: 0 10px
                margin-bottom: 48px
            & > div:nth-child(2), .block-box
                padding: 32px 24px 
                box-shadow: 0px 0px 20px #1917381F
                background-color: rgba(#F8F8FA, 0.5) 
                &.icon-block
                    display: flex
                    background: unset
                    box-shadow: unset
                    padding: 36px
                    img, .img
                        margin: auto
                
                .login-link
                    text-align: center
                    text-transform: capitalize

            label
                text-transform: capitalize

            .flex-betwen
                display: flex
                flex-direction: row
                justify-content: space-between

            button
                width: 100%
                height: 34px
                background: #1890FF 0% 0% no-repeat padding-box
                border-radius: 62px
                font: 14px/24px "Red Hat Display Regular"
                &:not([disabled])
                    &:hover
                        background-color: #fff
                        color:  #1890FF
            &.login
                margin-bottom: calc((100vh - 425px) * 26 / 100)
            .change-pass-form
                input
                    border-left-width: 0px
                    padding-left: 0px
                    border-right: 1px solid #D6D6DF
                    margin-right: 12px
                .ant-form-item
                    &:nth-child(1)
                        margin-bottom: 24px
                    &:nth-child(2)
                        margin-bottom: 32px
                    &:nth-child(3)
                        margin-bottom: 0px
                    .ant-input-suffix
                        margin: 0
        .resend
            width: 447px
            .login-description
                padding: 0
        .forgot
            .ant-form-item
                &:nth-child(1)
                    margin-bottom: 32px
                &:nth-child(2)
                    margin-bottom: 28px
                &:nth-child(3)
                    margin-bottom: 0px
                    height: 22px
            .login-description
                margin-left: 0
                margin-right: 0
            & form>div
                &:nth-child(1)
                    margin-bottom: 24px
                &:nth-child(2)
                    margin-bottom: 16px

            // h2

            p
                width: 100%
        .pasword-changed
            .ant-form-item
                &:nth-child(1)
                    margin-bottom: 0px
            h2
                color: $reset-successful-popup-title-color
    &.loading-silent
        display: none