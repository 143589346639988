@import "../../../assets/sass/base/_colors"

.top-block
    width: 100%

    & > div:nth-child(1)
        width: 42.85%
        min-width: 260px

    & > div:nth-child(2)
        width: 16.58%
        min-width: 260px

    & > div:nth-child(3)
        width: 37.5%
        min-width: 260px
    .ant-dropdown-menu-item
        span
            text-transform: capitalize
            font: 14px/21px "Red Hat Display Regular"

.isUp
    color: $statistics-up-color

.isDown
    color: $statistics-down-color