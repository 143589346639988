.earning-chart
    max-width: 100%
    width: 100%
    .chart-block, .ant-space-horizontal
        max-width: 100%
    .chart-block
        position: relative
        width: 100%
        min-height: 220px
        min-width: 210px
        margin-top: 18px
        padding: 20px

        & > *
            position: absolute
        & > div
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            text-align: center
            .isUp, .isDown
                font: 24px/19px "Red Hat Display Medium"
            .medium
                font: 14px/21px "Red Hat Display Medium"

                
