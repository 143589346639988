.reset-pass-success-modal
    .ant-modal-header
        padding-bottom: 32px
    .title-block
        width: 100%
        margin-top: 8px
        .ant-space-item
            font-size: 40px
            color: $reset-successful-popup-title-color
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap
        .title
            font: 23px/6px "Red Hat Display Regular"
    .pass-input-block
        width: 100%

        & > .ant-space-item:nth-child(1)
            width: 93%
            & .anticon
                color: rgba($project-global-color, 0.7)
                &:hover,&:active
                    color: $icon-focus-color
                &:focus
                    color: $icon-focus-color

        & > .ant-space-item:nth-child(2)
            margin-top: 10px
            color: rgba($project-global-color, 0.7)
            &:hover
                color: $project-global-color
            &:focus, &:active
                color: $icon-focus-color
    .MainModal--footer.small-footer
        padding-left: 0px

        .ant-form-item-control-input-content
            justify-content: space-between

            .default
                background-color: transparent
                margin-left: 0
                font: 15px/20px "Red Hat Display Regular"
                color: $project-global-color
                padding: 4px 8px
                &:hover
                    color: $icon-focus-color
                &:active,&:focus
                    color: $icon-focus-color
                    background: $select-dropdown-bg-color 0 0 no-repeat padding-box
                    border-radius: 14px
            .miniBtn
                padding-top: 9px
                padding-bottom: 9px
            
