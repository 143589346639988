.role-item
    min-width: 300
    max-width: 374
    width: 22%
    display: flex
    flex-direction: column
    padding: 16px 8px 16px 16px
    height: 166px
    background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
    box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
    border-radius: 12px
    position: relative

    .anticon[tabindex]
      font-size: 18px
      height: 18px
      margin-bottom: auto
      color: $icon-color
      &:hover
        color: $icon-hover-color
        
    .role-item-header
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin-bottom: 16px
        padding-right: 8px
        .title
            font: 14px/19px "Red Hat Display Bold"
            letter-spacing: 0px
            width: 80%
            .ant-skeleton-active
                max-width: 50%
                overflow: hidden
        & > .ant-skeleton-element
            position: absolute
            right: 16px
            top: 16px
    .role-description
        font: 14px/27px "Red Hat Display Regular"
        letter-spacing: 0
        overflow-y: auto
        .ant-skeleton-element
            max-width: 90%
            overflow: hidden
            height: 18px
            &:nth-child(1)
                margin-top: 10px
                
