@import "../base/_colors"

.titleArea
  display: flex
  align-items: center
  justify-content: space-between
  padding: 24px 0
  position: relative

  &--title
    text-align: left
    font: 32px/38px "SF Pro Display Bold"
    letter-spacing: 0
    color: $content-title-color
    opacity: 0.3
    text-transform: capitalize
  &.collapsedTitle
    & .ant-collapse
      width: 100%
      background: transparent
      & .ant-collapse-item
        &.ant-collapse-item-disabled
          & > .ant-collapse-header,
          & > .ant-collapse-header > .arrow
            cursor: default
        border-color: transparent
        & .ant-collapse-header
          text-align: left
          font: 32px/38px "SF Pro Display Bold"
          letter-spacing: 0
          color: #19173830
          text-transform: capitalize
          display: flex
          align-items: center
          justify-content: space-between
          flex-direction: row-reverse
          & .ant-collapse-extra
            text-align: left
            font: 32px/38px "SF Pro Display Bold"
            letter-spacing: 0
            color: #19173830
            text-transform: capitalize
            width: 100%
