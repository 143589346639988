@import "../base/_colors"
.mainBtn
  border: 1px solid #DCDCE4
  background: #FFFFFF
  border-radius: 54px
  opacity: 1
  text-align: left
  font: 15px/20px "Red Hat Display Medium"
  letter-spacing: 0
  color: $botton-default-color
  padding: 8px
  display: flex
  align-items: center
  justify-content: center
  transition: border-color ease-in-out 0.3s
  cursor: pointer
  line-height: 16px
  &:focus
    color: $botton-focus-color
    & .icon
      background: $botton-focus-color
      border-color: $botton-focus-color
      &:before, &:after
        background: #fff
  & .icon
    position: relative
    width: 20px
    height: 20px
    background: #fff 0 0 no-repeat padding-box
    border: 2px $botton-default-color solid
    border-radius: 50%
    margin-right: 8px

    &:before
      content: ""
      width: 12px
      height: 2px
      background: $botton-default-color
      position: absolute
      top: calc(50% - 1px)
      left: 2px

    &:after
      content: ""
      width: 2px
      height: 12px
      background: $botton-default-color
      position: absolute
      top: 2px
      left: calc(50% - 1px)

  &:hover
    color: $botton-default-color
    & .icon
      background: $botton-default-color
      border-color: $botton-default-color
      &:before, &:after
        background: #fff

.miniBtn 
  background: $botton-default-color 0 0%no-repeat padding-box
  border-radius: 62px
  text-align: center
  font:  14px/24px "SF Pro Display Regular"
  letter-spacing: 0
  color: #FFFFFF
  padding: 10px 38px
  cursor: pointer
  border: transparent
  line-height: 16px
  &.ant-btn
    background: $botton-default-color 0 0%no-repeat padding-box
  &.default
    background: #F8F8FA 0 0 no-repeat padding-box
    border-radius: 52px
    font: 14px/24px "SF Pro Display Regular"
    letter-spacing: 0
    color: $project-global-color
    padding: 10px 32px
    text-align: center
    line-height: 16px
    &.border
      border: 1px solid #DCDCE4
    &:hover,
    &.ant-btn:hover
      background: #F8F8FA 0 0 no-repeat padding-box
      color: $botton-default-color
    &:focus,
    &.ant-btn:focus
      background: #F8F8FA 0 0 no-repeat padding-box
      color: $botton-focus-color

  &[disabled]
    cursor: unset
    opacity: 0.5
  & .icon
    width: 16px
    height: 16px
    margin: 0 8px 0 0
  &.small
    padding: 10px 24px
  &.bg_dark
    background-color: #191738
  &:not(.btnRadio):not(.default):not(.miniBtn[disabled]):hover,
  &.ant-btn:not(.btnRadio):not(.default):not(.miniBtn[disabled]):hover
    background: $botton-focus-color !important
  &:not(.btnRadio):not(.default):not(.miniBtn[disabled]):focus,
  &.ant-btn:not(.btnRadio):not(.default):not(.miniBtn[disabled]):focus
    background: $logo-text-color !important
  &.btnRadio
    &:hover
      color: rgba($icon-focus-color, 0.7)
    &:focus
      color: $icon-focus-color
.durationsBtn
  position: relative
  width: 36px
  height: 32px
  background: #F8F8FA 0 0 no-repeat padding-box
  border: 1px solid #DCDCE4
  border-radius: 2px
  opacity: 1
  cursor: pointer
  line-height: 16px
  &:before
    content: ""
    width: 2px
    height: 13px
    background-color: #191738
    opacity: 0.5
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
  &:after
    content: ""
    width: 2px
    height: 13px
    background-color: #191738
    opacity: 0.5
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    transform: rotate(90deg)
  &.remove
    &:before
      transform: rotate(45deg)
    &:after
      transform: rotate(135deg)
  &[disabled]
    cursor: not-allowed
    opacity: 0.5

.btn_1
  background: $project-text-color 0 0 no-repeat padding-box
  border-radius: 2px
  text-align: left
  font: 300 14px/22px "SF Pro Display Regular"
  letter-spacing: 0
  color: #FFFFFF
  display: flex
  align-items: center
  justify-content: center
  transition: border-color ease-in-out 0.3s
  cursor: pointer
  border: 1px solid $project-text-color
  padding: 5px 13px
  line-height: 16px
  & .icon
    position: relative
    width: 24px
    height: 24px
    margin: 0 0 0 8px

    &:before
      content: ""
      width: 12px
      height: 2px
      background: #FFFFFF
      position: absolute
      top: calc(50% - 1px)
      left: 6px

    &:after
      content: ""
      width: 2px
      height: 12px
      background: #FFFFFF
      position: absolute
      top: 6px
      left: calc(50% - 1px)
  &[disabled]
    opacity: 0.5
    cursor: not-allowed
.filterBtn
  position: relative
  background: #FFFFFF 0 0 no-repeat padding-box
  border: 1px solid #DCDCE4
  border-radius: 2px
  text-align: right
  font: normal normal normal 14px/24px "SF Pro Display Regular"
  letter-spacing: 0
  color: #191738
  padding: 8px 24px 8px 12px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  &:before
    content: ""
    position: absolute
    right: 12px
    width: 7px
    height: 7px
    border-top: 1px solid #bab9c3
    border-right: 1px solid #bab9c3
    transform: rotate(45deg)
    transition: 0.3s ease-in-out
  & img
    width: 24px
    height: 24px
  &.show
    &:before
      transform: rotate(135deg)
  &:hover,&:focus,&:active
    color: #191738 !important

