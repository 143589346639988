.earning-table-block
    height: auto
    margin-bottom: 24px
    gap: 0px !important
    .ant-table-content
        overflow-x: auto
    & > .ant-space-item:nth-child(2)
        width: 100%
        .ant-space,
        .ant-space-item
            width: 100%
    span
        white-space: nowrap
        font: 15px/19px "Red Hat Display Regular"
        color: $project-text-color
    .ant-table-wrapper .ant-table-tbody > tr:nth-child(2n), .ant-table-wrapper .ant-table-tbody > tr:nth-child(2n) td
        background-color: #F8F8FA !important
    .ant-table-wrapper .ant-table-tbody > tr, .ant-table-wrapper .ant-table-tbody > tr td
        background-color: #F8F8FA !important
    .app-img
        img
            width: 40px
            height: 40px
            margin-right: 10px
            border-radius: 5px
    .mainTable .ant-table-wrapper table .ant-table-tbody > tr > td:not(:last-child, .noPadding)
        padding: 8px 50px 8px 16px
    .noPadding
        padding: 0
        min-width: 0 !important
    .noPaddingRight
        padding-right: 10px !important
    .mainTable
        margin-bottom: 0px
        .ant-table-wrapper
            margin-top: 0
