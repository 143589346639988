@import "../base/_colors"

#horizontal_Time
  display: flex
  align-items: flex-start
.ant-form-item-has-error

  .ant-select-selection-placeholder
    color: rgba($error-validation-color, 0.5) 
  
  input, textarea
    color: $error-validation-color
    &::placeholder 
      color: rgba($error-validation-color, 0.5) !important
    &:-ms-input-placeholder
      color: rgba($error-validation-color, 0.5) !important
    &::-ms-input-placeholder
      color: rgba($error-validation-color, 0.5) !important