.valid-pass
    *
        color: #ffffff
    .ant-popover-content
        span
            display: block
            font-family: "Red Hat Display Regular"
            i:before
                font-size: 10px
                margin-right: 9px
        .ant-popover-arrow
            border-bottom-color: #161829 !important
            border-left-color: #161829 !important
    .ant-popover-inner
        background-color: #161829
        .ant-popover-inner-content
            padding: 12px 13px
        .ant-popover-title
            border-color:  #161829
            text-transform: capitalize
            color: #ffffff
            font-family: "Red Hat Display Bold"
            font-weight: 800
        
            