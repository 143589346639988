@import '../base/_colors'

.user-card-item
    display: flex
    flex-direction: column
    min-width: 374px
    width: calc(25% - 18px)
    min-height: 220px
    position: relative

    .header
        position: relative
        min-height: 50px
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding: 16px
        padding-left: 104px
        background: rgba(246, 255, 237, 1) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 12px 12px 0px 0px
        span:not([role="img"])
            font: 14px/19px "Red Hat Display Regular"
            letter-spacing: 0px
            color: rgba(82, 196, 26, 1)
            text-transform: capitalize
        span[role="img"]
            margin-left: auto
            margin-right: 12px
            cursor: pointer
            font-size: 18px
            color: $icon-color
            &:hover
                color: $icon-hover-color
            &.last
                margin-left: 0 !important
                margin-right: 0 !important
            

                
        img, div.img
            position: absolute
            width: 72px
            height: 72px
            object-fit: cover
            border-radius: 50%
            left: 16px
            top: 16px
            border: 1px rgba(82, 196, 26, 1) solid
        div.img
            background-color: #fff

    .body
        background: transparent linear-gradient(180deg, rgba(248, 248, 250, 1) 0%, rgba(242, 242, 247, 1) 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
        border-radius: 0px 0px 12px 12px
        height: calc(100% - 50px)
        .detals
            padding-left: 104px
            h4
                font: 17px/19px "Red Hat Display Medium"
                letter-spacing: 0px
                padding: 8px
                margin: 0
                padding-left: 0
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
            h5
                font: 14px/19px "Red Hat Display Regular"
                letter-spacing: 0
        .roles
            display: flex
            flex-wrap: nowrap
            gap: 12px
            padding: 16px
            max-width: 100%
            overflow: hidden

            & > div:nth-child(1)
                display: flex
                flex-direction: column
                span
                    text-transform: lowercase
                    font: 14px/19px "Red Hat Display Medium"
                    &::first-letter
                        text-transform: uppercase
                    &:nth-child(2)
                        margin-top: 20px

            & > div:nth-child(2)
                display: flex
                flex-direction: column
                min-width: 283px
                max-width: 285px

                .ant-select:not(.ant-select-customize-input) .ant-select-selector
                    background-color: transparent
                    border: 0 solid #d9d9d9
                

                .rol-block
                    display: flex
                    flex-wrap: nowrap
                    gap: 12px
                    width: 100%

                    span.ant-select-selection-item
                        font: 14px/22px "Red Hat Display Regular"
                        background: rgba(248, 248, 250, 1) 0 0 no-repeat padding-box
                        border: 1px solid rgba(220, 220, 228, 1)
                        border-radius: 2px
                        padding: 0 8px
                        white-space: nowrap
                        display: flex
                        align-items: center
                        justify-content: center

                        // .anticon-close
                        //     display: none

                    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input, .select-after) .ant-select-selector
                        box-shadow: unset

                    .ant-select-selection-overflow-item-suffix
                        display: none

                    .ant-select:not(.ant-select-disabled):hover .ant-select-selector
                        border-right-width: 0px !important

                    .ant-select-selection-overflow-item-rest > span
                        background: transparent
                        border: 0px solid #dcdce4
                        & .ant-dropdown-trigger
                            font: 14px/22px "Red Hat Display Bold"
                            letter-spacing: 3px
                
                .app-block
                    display: flex
                    flex-wrap: nowrap
                    gap: 12px
                    margin-top: 16px
                    max-width: 257px
                    overflow-x: auto
                    img
                        width: 32px
                        height: 32px
                        object-fit: cover
                        // border: 0px solid rgba(25, 23, 56, 1)
                        border-radius: 4px
                        transition: transform .4s
                        // &:hover
                        //     transform: scale(1.3)
    &.blocked
        .header
            background: rgba(255, 241, 240, 1) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(245, 34, 45, 1)
            img
                border: 1px rgba(245, 34, 45, 1) solid

    &.inactive
        .header
            background: rgba(237, 245, 255, 1) 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(124, 144, 165, 1)
            img
                border: 1px rgba(124, 144, 165, 1) solid
    &.loading
        .header
            background: #e8e8eb 0% 0% no-repeat padding-box
            box-shadow: inset 0px 0px 1px rgba(25, 23, 56, 0.12), 0px 1px 2px rgba(25, 23, 56, 0.12)
            span:not([role="img"])
                color: rgba(124, 144, 165, 1)
            img, .img
                border: 1px rgba(124, 144, 165, 1) solid
                
.roles-drop-down
    .ant-dropdown-menu-item
        padding-left: 24px 
        padding-right: 24px 
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title
        cursor: default