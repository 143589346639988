@import "../base/_colors"

.formModal
  &.ant-modal
    &.loading

      & .ant-modal-close
        display: none

    & .ant-modal-header
      padding: 24px 24px 18px 24px
      border-bottom: transparent

    & .ant-modal-title
      text-align: left
      font: 23px/6px "Red Hat Display Bold"
      letter-spacing: 0
      color: $popup-title-color
      margin: 5px 0

    & .ant-modal-body
      padding: 0 24px 24px 24px

    & .MainModal
      overflow: auto
      &.infoPopUp
        padding: 0 0 24px 0
        & .MainModal--parts:first-child
          border-right: 0
      &--parts
        padding: 0 24px

        & .withList
          height: 540px
          padding: 0 0 0 0

        &:first-child
          border-right: 1px solid #DCDCE4

        & .ant-col.ant-form-item-label
          text-align: left
          font: 14px/22px "SF Pro Display Regular"
          letter-spacing: 0
          color: #191738
          padding: 0

      &--footer
        margin-bottom: 0 !important
        border-top: 1px solid #f0f0f0
        padding: 24px 0px 0 24px
        margin-top: 24px

        & .ant-form-item-control-input-content
          display: flex
          align-items: center
          justify-content: flex-end

        & button
           margin: 0 4px
        & button.submit:hover
          & + .error_box
            display: block
        & .error_box
          position: absolute
          left: calc(100% + 5px)
          top: 0px
          transform: translateY(-5px)
          display: none
          width: 282px
          border-radius: 4px
          opacity: 1
          padding: 12px 20px
          color: #191738
          background: #FFF1F0 0% 0% no-repeat padding-box
          box-shadow: 0px 0px 15px #1a183b33
          font: 15px/21px "Red Hat Display Regular"
          border: 1px solid rgba(#FF4D4F, 0.4)
          & .required
            color: #FF4D4F
          &::after
            content: ''
            width: 7px
            height: 7px
            display: block
            border-left: 1px solid rgba(#FF4D4F, 0.4)
            border-top: 1px solid rgba(#FF4D4F, 0.4)
            transform: rotate(-45deg) translateY(-50%)
            position: absolute
            left: -2px
            top: 50%
            background-color: #FFF1F0
          
.tabMode
  width: 100%
  text-align: center
  margin-bottom: 24px
  & .ant-radio-group.ant-radio-group-outline
    background: #F0F2F5 0 0 no-repeat padding-box
    // border: 0.5px solid rgb(0, 39, 102, 19%)
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    // max-width: 500px
    &.invalid
      background: #FFF1F0 0% 0% no-repeat padding-box
      border: 1px solid #FF4D4F
      border-radius: 4px
    & .ant-radio-button-wrapper
      min-width: 273px
      text-align: center
      background: transparent
      border: transparent
      &:hover
        color: rgba($botton-default-color, 0.7)
      &:focus
        color: $botton-default-color
      &:before
        display: none
      &.invalid
          color: rgb(226, 95, 95, 0.9)
      &.ant-radio-button-wrapper-checked
        background: $selected-tab-color 0 0 no-repeat padding-box
        border: 0.5px solid $selected-tab-border-color
        border-radius: 4px
        text-align: center
        font: 14px/20px "Red Hat Display Medium"
        letter-spacing: 0
        color: #FFFFFF